<template>
  <div id="data-model-container" class="vs-con-loading__container">
    <div v-if="itemData" class="data-list-container">
      <div class="header-slicer">
        <vs-row vs-w="12" vs-justify="space-between" vs-align="center">
          <vx-tooltip text="Go Back" position="bottom" class="mr-4">
            <feather-icon 
              icon="ArrowLeftIcon" 
              class="cursor-pointer mr-2"
              svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer"
              @click.stop="goBack()" 
            />
          </vx-tooltip>
          <div>
            <vs-button color="warning" @click.stop="saveSlicedDocument" class="mr-4">Save</vs-button>
            <vs-button color="success" @click.stop="publishSlicedDocument" class="mr-4">Publish</vs-button>
            <vs-button color="danger" @click.stop="resetSlicedDocument">Reset</vs-button>
          </div>
        </vs-row>
      </div>
      <div class="center grid mt-10">
        <v-overlay :value="isImagePreviewVisible" zIndex="9999999" opacity="0.75">
          <div class="img-fullscreen-container"> 
            <image-zoom
              :regular="imagePreview.s3Url"
              :click-zoom="true"
              img-class="img-fullscreen"
            ></image-zoom>
          </div>
          <div class="minimize-icon">
            <feather-icon 
              class="cursor-pointer" 
              icon="Minimize2Icon" 
              svgClasses="text-black stroke-current w-8 h-8" 
              @click.stop="openImagePreview({}, false)"
            />
          </div>
        </v-overlay>
        <vs-row vs-w="12">
          <vs-col
            v-for="page in itemData.pages"
            :key="page.uuid"
            vs-w="4"
            vs-type="flex"
            type="flex"
            vs-justify="center"
            vs-align="flex-start"
          >
            <div class="p-4">
              <vs-card class="cardx">
                <div slot="media" style="position:relative;">
                  <image-zoom
                    :regular="page.s3Url"
                    :click-zoom="true"
                  ></image-zoom>
                  <div class="maximise-icon">
                    <feather-icon 
                      class="cursor-pointer" 
                      icon="Maximize2Icon" 
                      svgClasses="text-black stroke-current w-8 h-8" 
                      @click.stop="openImagePreview(page, true)"
                    />
                  </div>
                </div>
                <div>
                  <vs-row vs-justify="space-between" class="p-4">
                    <div v-if="page.batesNumber">{{page.batesNumber}}</div>
                    <vs-checkbox color="primary" class="mx-4" v-if="!page.isBreakpoint" v-model="page.isIgnored">Ignore</vs-checkbox>
                    <vs-checkbox color="primary" class="mr-4" v-model="page.isBreakpoint">Break</vs-checkbox>
                  </vs-row>
                  <vs-row class="mt-2 px-4" v-if="page.isBreakpoint">
                    <v-select multiple v-model="page.groupType" :options="documentTypes" placeholder="Types" class="w-full" :searchable="true" autocomplete="on" />
                  </vs-row>
                  <vs-row class="p-4" v-if="page.isBreakpoint">
                    <vs-input 
                      class="inputx w-full" placeholder="Title" 
                      v-model="page.groupTitle" 
                    />
                  </vs-row>
                </div>
              </vs-card>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
  import moduleTodo from '@/store/todo/moduleTodo.js'
  import _ from 'lodash'
  import imageZoom from 'vue-image-zoomer';
  import vSelect from 'vue-select'

  export default {
    components: {
      imageZoom,
      vSelect
    },
    data () {
      return {
        isImagePreviewVisible: false,
        imagePreview: {},
        interval: null,
        itemData: {},
        slicingInfo: [],
        accountId: this.$route.params.accountId,
        caseId: this.$route.params.caseId,
        documentId: this.$route.params.documentId,
        documentTypes: [
          "Chronology",
          "Court Field Requests",
          "Depositions",
          "Doctors",
          "Emails",
          "Expert Reports",
          "Hospitals / Medical Facilities",
          "Hot Items",
          "Image",
          "Inspection Reports",
          "Interview & Statements",
          "Invoices",
          "Key People",
          "Logs",
          "Manuals",
          "Maps",
          "Medical Billing",
          "Medical Imaging",
          "Medical Reports",
          "Other",
          "Patient Info",
          "Personnel Info",
          "Pictures",
          "Police Reports / 911",
          "Presentations",
          "Psychology",
          "Reports & Studies",
          "Specialists",
          "Surgery",
          "Technical Info & Specs",
          "Transportation",
          "Videos",
          "Native"
        ]
      }
    },
    created () {
      if (!this.$store.hasModule("todo")) {
        this.$store.registerModule('todo', moduleTodo)
      }
    },
    mounted () {
      this.refreshData()
    },
    methods: {
      refreshData() {
        this.$vs.loading({
          container: '#data-model-container',
        })
        this.$store.dispatch('todo/fetchSlicerDocument', {
          accountId: this.accountId,
          caseId: this.caseId,
          uuid: this.documentId
        }).then(response => {
          let itemData = response.item;
          if (response.item.slicingInfo && response.item.slicingInfo.length > 0) {
            response.item.slicingInfo.map(groupItem => {
              if (groupItem.pageRange && groupItem.pageRange.length >= 1) {
                itemData.pages[groupItem.pageRange[0]].isBreakpoint = true;
                if (groupItem.groupType) {
                  itemData.pages[groupItem.pageRange[0]].groupType = groupItem.groupType;
                } else itemData.pages[groupItem.pageRange[0]].groupType = [];
                if (groupItem.groupTitle) {
                  itemData.pages[groupItem.pageRange[0]].groupTitle = groupItem.groupTitle;
                }
                if (groupItem.pageRange.length > 1) {
                  for (let start = groupItem.pageRange[0]; start <= groupItem.pageRange[groupItem.pageRange.length - 1]; start++) {
                    if (groupItem.pageRange.indexOf(start) === -1) itemData.pages[start].isIgnored = true;
                  }
                }
              }
            });
          } else if (itemData.pages && itemData.pages.length === 1) {
            itemData.pages[0].isBreakpoint = true;
          }
          console.log(itemData);
          this.itemData = itemData;
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        }).catch(error => {
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        });
      },
      setupSlicingData() {
        try {
          console.log("here");
          let pageGroups = [], groupItem = {pageRange: [], groupType: [], groupTitle: ""};
          this.itemData.pages.map((page, index) => {
            if (!page.isBreakpoint && groupItem.pageRange.length > 0 && !page.isIgnored) {
              groupItem.pageRange.push(index);
            }
            if (page.isBreakpoint) {
              if (groupItem.pageRange.length > 0) {
                pageGroups.push(_.clone(groupItem));
                groupItem = _.clone({pageRange: [], groupType: [], groupTitle: ""});     
              } 
              groupItem.pageRange.push(index);  
              if (page.groupType) groupItem.groupType = page.groupType;
              if (page.groupTitle) groupItem.groupTitle = page.groupTitle;
              if (this.itemData.pages.length === 1) {
                pageGroups.push(_.clone(groupItem));
                groupItem = _.clone({pageRange: [], groupType: [], groupTitle: ""});
              }
            }
          });
          if (groupItem.pageRange.length > 0) {
            pageGroups.push(_.clone(groupItem));
            groupItem = _.clone({pageRange: [], groupType: [], groupTitle: ""});
          }
          console.log(pageGroups);
          this.slicingInfo = pageGroups;
        } catch(e){}
      },
      handleNavigation() {
        try {
          if (this.$route.query.source) {
            switch(this.$route.query.source) {
              case "account-case-documents":
              case "case-document-readyforslicing":
              case "case-document-full-bates":
                this.$router.push({name: this.$route.query.source, params: {caseId: this.caseId, accountId: this.accountId}})  
                return true;
            }
          }
        } catch(error) {}
        return false;
      },
      goBack() {
        let result = this.handleNavigation();
        if (!result) {
          this.$router.push({name: "case-document-readyforslicing", params: {caseId: this.caseId, accountId: this.accountId}})
        }
      },
      saveSlicedDocument() {
        try {
          this.setupSlicingData();
          this.$vs.loading({
            container: '#data-model-container',
          });
          this.$store.dispatch('todo/saveSlicerDocument', {
            accountId: this.accountId,
            caseId: this.caseId,
            uuid: this.documentId,
            slicingInfo: this.slicingInfo
          }).then(response => {
            this.$vs.notify({
              title: 'Success',
              text: 'Saved document state successfully',
              color: 'success',
              iconPack: 'feather',
              icon: 'icon-check'
            });
            this.$vs.loading.close('#data-model-container > .con-vs-loading')
          }).catch(error => {
            console.log(error);
            this.$vs.notify({
              title: 'Error',
              text: 'Error saving document state',
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            })
            this.$vs.loading.close('#data-model-container > .con-vs-loading')
          });
        } catch(e){}
      },
      download(urls) {

        let url = urls.pop();

        let a = document.createElement("a");
        a.setAttribute('href', url);
        a.setAttribute('download', '');
        a.setAttribute('target', '_blank');
        a.click();

        if (urls.length === 0 && this.interval) {
          clearInterval(this.interval);
        }
      },
      fetchNextDocumentToSliceInQueue() {
        this.$vs.loading({
            container: '#data-model-container',
          });
        this.$store.dispatch('todo/fetchCaseDocumentList', {
          accountId: this.accountId,
          caseId: this.caseId
        }).then(response => {
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
          if (response && response.items) {
            const pendingDocs = response.items.filter(item => !item.isSliced && item.uuid != this.documentId)
            if (pendingDocs && pendingDocs.length > 0 && pendingDocs[0].uuid) {
              this.$router.push({
                name: "case-document-pdfslicer", 
                params: {
                  accountId: this.accountId,
                  caseId: this.caseId,
                  documentId: pendingDocs[0].uuid
                },
                query: {
                  source: "account-case-documents"
                }
              });
              this.documentId = pendingDocs[0].uuid
              this.refreshData()
            } else {
              this.$vs.notify({
                title: 'Not Found',
                text: 'No new documents found for slicing',
                color: 'warning',
                iconPack: 'feather',
                icon: 'icon-alert'
              });
            }
          }
        }).catch(error => {
          this.$vs.loading.close('#data-model-container > .con-vs-loading')
        });
      },
      publishSlicedDocument() {
        try {
          this.setupSlicingData();
          this.$vs.loading({
            container: '#data-model-container',
          });
          this.$store.dispatch('todo/publishSlicerDocument', {
            accountId: this.accountId,
            caseId: this.caseId,
            uuid: this.documentId,
            slicingInfo: this.slicingInfo
          }).then(response => {
            console.log(response);
            this.$vs.notify({
              title: 'Success',
              text: 'Sliced document published successfully',
              color: 'success',
              iconPack: 'feather',
              icon: 'icon-check'
            });
            this.$vs.loading.close('#data-model-container > .con-vs-loading')
            this.$vs.dialog({
              type: 'confirm',
              color: 'warning',
              title: 'Next Document',
              text: `Do you want to go to next document now?`,
              accept: this.fetchNextDocumentToSliceInQueue,
              acceptText: 'Yes'
            })
          }).catch(error => {
            console.log(error);
            this.$vs.notify({
              title: 'Error',
              text: 'Error publishing sliced document',
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle'
            })
            this.$vs.loading.close('#data-model-container > .con-vs-loading')
          });
        } catch(e){}
      },
      resetSlicedDocument() {
        try {
          for(let i = 0; i < this.itemData.pages.length; i++) {
            if (this.itemData.pages[i].isBreakpoint) this.itemData.pages[i].isBreakpoint = false;
            if (this.itemData.pages[i].groupType) this.itemData.pages[i].groupType = [];
          }
          this.slicingInfo = [];
        } catch(e){}
      },
      openImagePreview(page, visibility) {
        this.isImagePreviewVisible = visibility;
        this.imagePreview = page;
      }
    }
  }
</script>
<style lang="scss">
  .vs-card--content {
    background-color: #1E1E1E;
  }
  .vs-checkbox-primary input:checked+.vs-checkbox {
    background-color: transparent;
  }
  .header-slicer {
    position: fixed;
    top: 62px;
    padding: 8px 2.2rem;
    left: 0;
    width: 100%;
    box-shadow: 0 -4px 20px 0 rgba(0, 0, 0, .05);
    z-index: 1000000;
    background-color: #1a1a1a;
  }
  .maximise-icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .minimize-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #fff;
    border-radius: 50%;
    width: 36px;
    height: 36px;
  }
  .minimize-icon .feather-icon {
    top: 5px;
    left: 5px;
  }
  .minimize-icon .feather-icon .feather {
    width: 24px;
    height: 24px;
  }
  .img-fullscreen-container {
    width: 90vw;
    height: 90vh;
  }
  .img-fullscreen-container .vh--outer {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
  }
  .img-fullscreen {
    max-width: 100%;
    max-height: 90vh;
  }
  .vs__search {
    background: none !important;
    color: white !important;
  }
  .vs__dropdown-option--highlight {
    background: #1a1a1a !important;
  }
</style>
